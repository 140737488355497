import { useTranslation } from "react-i18next";
import {useNavigate, useOutletContext} from "react-router-dom";
import React, {useEffect, useState} from "react";
import { RiderProfileLayoutContextInterface } from "../RiderProfileLayout";
import ImageInput from "../../../Components/ImageInput";
import {patrollerService} from "../../../Api/Services/PatrollerService";
import {useLoading} from "../../../Context/LoadingProvider";
import {useInfoModal} from "../../../Context/InfoModalProvider";

const QuestionerStep: React.FunctionComponent<any> = () => {
    const { t } = useTranslation();
    const {
        setSubTitle,
        rider,
        setShowProfileInfo,
    } = useOutletContext<RiderProfileLayoutContextInterface>();
    const navigate = useNavigate();
    const { startLoading, finnishLoading } = useLoading();
    const [questionerQuestions, setQuestionerQuestions] = useState<any[]>([]);
    const [questionerQuestionResults, setQuestionerQuestionResults] = useState<any[]>([]);
    const [currentQuestioneNumber, setCurentQuestionNumber] = useState<number>(0);
    const [currentImage, setCurrentImage] = useState<File[]>([]);
    const [skippedQuestions, setSkippedQuestions] = useState<any[]>([]);
    const [showErrorMessages, setShowErrorMessages] = useState<boolean>(false);
    const { showInfo } = useInfoModal();


    useEffect(() => {
        setSubTitle();
    }, [t, setSubTitle]);

    setSubTitle(t('Action'));

    const fetchQuestions = () => {
        startLoading('fetch-questions');
        patrollerService.getQuestionerQuestions().then((questions) => {
            setQuestionerQuestions(questions);
        }).finally(() => {
            finnishLoading('fetch-questions');
        });
    }

    useEffect(() => {
        fetchQuestions();
        setShowProfileInfo(false);
        // eslint-disable-next-line
    }, []);

    // questioner_answers:[
    //     {
    //         questionerQuestionId: int,
    //         answer: string|null,
    //         score: int|null,
    //         imageUrl: string|null,
    //     }
    // ]

    const getOptionNameForCurrentLanguage = (option: any) => {
        return option.name;
    }

    const getOptionNameForCurrentLanguageText = (option: any) => {
        const lang = localStorage.getItem("language") ?? "en";

        if (lang === "ar") {
            return option.name_ar ?? option.name;
        }

        return option.name;
    }

    const getQuestionForCurrentLanguage = (question: any) => {
        const lang = localStorage.getItem("language") ?? "en";

        if (lang === "ar") {
            return question.question_ar ?? question.question;
        }

        return question.question;
    }

    useEffect(() => {
        const questionerQuestionsResultsMap = [];

        for (let i = 0; i < questionerQuestions.length; i++) {
            questionerQuestionsResultsMap.push({
                questionerQuestionId: questionerQuestions[i].id,
                questionerQuestion: questionerQuestions[i],
                answer: questionerQuestions[i].type === 'MULTIPLE_CHOICE:MULTI' ? [] : null,
                score: null,
                imageUrl: [],
            });
        }

        setQuestionerQuestionResults(questionerQuestionsResultsMap);
    }, [questionerQuestions]);


    const handleNext = () => {
        let skippedQuestionsSync = [...skippedQuestions];
        if (!questionerQuestionResults[currentQuestioneNumber].answer && questionerQuestionResults[currentQuestioneNumber].questionerQuestion.mandatory) {
            setShowErrorMessages(true);
            setTimeout(() => {
                setShowErrorMessages(false);
            }, 5000)
            return;
        }

        if (questionerQuestionResults[currentQuestioneNumber].imageUrl.length === 0 && parseInt(questionerQuestionResults[currentQuestioneNumber].questionerQuestion.image_mandatory) === 1) {
            return;
        }

        setCurrentImage([]);

        if (['MULTIPLE_CHOICE:SINGLE', 'MULTIPLE_CHOICE:MULTI'].includes(questionerQuestions[currentQuestioneNumber].type)){
            let options = questionerQuestions[currentQuestioneNumber].options;
            if (!options){
                setCurentQuestionNumber(currentQuestioneNumber + 1);
            }

            let currentOption = options.find((x: any) => getOptionNameForCurrentLanguage(x) === questionerQuestionResults[currentQuestioneNumber].answer);

            if (currentOption && currentOption.score) {
                questionerQuestionResults[currentQuestioneNumber].score = parseInt(currentOption.score ?? '0');
            }

            if (questionerQuestions[currentQuestioneNumber].type === 'MULTIPLE_CHOICE:MULTI'){
                let currentOptions = options.filter((x: any) => questionerQuestionResults[currentQuestioneNumber].answer.includes(getOptionNameForCurrentLanguage(x)));

                for (let multiOption of currentOptions){
                    questionerQuestionResults[currentQuestioneNumber].score += parseInt(multiOption.score ?? '0');
                    currentOption = multiOption;

                }
            }

            if (currentOption && currentOption.jump){
                let nextQuestion = questionerQuestions.findIndex((x: any) => parseInt(x.order) === parseInt(currentOption.jump) );
                let questionsToSkip = [];

                for (let qi = currentQuestioneNumber + 2; qi < currentOption.jump; qi++){
                    questionsToSkip.push(qi);
                }

                if (nextQuestion !== -1){
                    setSkippedQuestions([...skippedQuestions, ...questionsToSkip]);
                    setCurentQuestionNumber(nextQuestion);
                    return;
                }
            }

            //eslint-disable-next-line
            if (currentOption && currentOption.skip && currentOption.skip != ''){
                let skipQuestions = currentOption.skip ?? [];
                skippedQuestionsSync = [...skippedQuestionsSync, ...skipQuestions];
                setSkippedQuestions([...skippedQuestions, ...skipQuestions]);
            }
        } else{
            questionerQuestionResults[currentQuestioneNumber].score = questionerQuestionResults[currentQuestioneNumber].answer !== '' && questionerQuestionResults[currentQuestioneNumber].answer != null ? questionerQuestions[currentQuestioneNumber].score : 0;
        }


        console.log(skippedQuestionsSync, currentQuestioneNumber, questionerQuestions.length, questionerQuestions[currentQuestioneNumber + 1])
        if (skippedQuestionsSync.length > 0){
            //search for the next question that is not skipped
            let addedIndex = 1;
            let nextQuestion = questionerQuestions[currentQuestioneNumber + addedIndex] ?? null;
            while(nextQuestion && skippedQuestionsSync.includes(nextQuestion.order.toString())){
                addedIndex++;
                nextQuestion = questionerQuestions[currentQuestioneNumber + addedIndex] ?? null;
            }
            if (nextQuestion){
                console.log('next question', nextQuestion);
                setCurentQuestionNumber(currentQuestioneNumber + addedIndex);
                return;
            }
        }

        if (currentQuestioneNumber + 1 < questionerQuestions.length) {
            setCurentQuestionNumber(currentQuestioneNumber + 1);
            return;
        }

        startLoading('questioner-verification');
// @ts-ignore
        patrollerService.saveQuestionerData(rider.riderId, questionerQuestionResults).then(async () => {
            await patrollerService.finishCheckForRider(rider.riderId);
            showInfo(t("Rider check saved successfully!"), () => {
                navigate(`/rider/${rider.riderId}/profile`);
            });
        }).finally(() => {
            finnishLoading('questioner-verification');
        });

    }

    useEffect(() => {
        if (currentImage.length > 0){
            questionerQuestionResults[currentQuestioneNumber].imageUrl = currentImage[0];
            setQuestionerQuestionResults([...questionerQuestionResults]);
        }
        //eslint-disable-next-line
    }, [currentImage]);
    if (questionerQuestions.length === 0 || questionerQuestionResults.length === 0){
        return (
            <div className="text-center">
                {t("Loading...")}
            </div>
        );
    }
    return (
        <>
            <div className="text-white text-center mb-4">
                {t("Survey Completion")}
            </div>
            <div className="questions-progressbar">
                <div className="questions-progressbar__progress" style={{width: `${(currentQuestioneNumber) / questionerQuestions.length * 100}%`}}></div>
                <p className="questions-progressbar__text">
                    {Math.round((currentQuestioneNumber) / questionerQuestions.length * 100)}%
                </p>
            </div>
            <div style={{height: "15px"}}></div>
            <div className="card">
                {showErrorMessages && <div className="text-red">{t("Please upload an image!")}</div>}
                <p className="text-center font-bold mb-4">
                    {getQuestionForCurrentLanguage(questionerQuestions[currentQuestioneNumber])}
                </p>
                <div className="flex justify-center items-center w-full">
                    {questionerQuestions[currentQuestioneNumber]?.imageUrl !== '' &&  <img src={questionerQuestions[currentQuestioneNumber]?.imageUrl} className="mb-4" alt=""/>}
                </div>


                {
                    questionerQuestions[currentQuestioneNumber].type === 'YES_NO' && (
                        <div className="flex justify-center">
                            <label className="checkbox mb-4">
                                <input
                                    type="checkbox"
                                    onChange={(e) => {
                                        questionerQuestionResults[currentQuestioneNumber].answer = 'YES';
                                        setQuestionerQuestionResults([...questionerQuestionResults]);
                                    }}
                                    required
                                    checked={questionerQuestionResults[currentQuestioneNumber].answer === 'YES'}
                                />
                                <div className="checkbox__success-icon no-border bg-gray"></div>
                                <div className="checkbox__text">{t("Yes")}</div>
                            </label>
                            <label className="checkbox mb-4">
                                <input
                                    type="checkbox"
                                    onChange={(e) => {
                                        questionerQuestionResults[currentQuestioneNumber].answer = 'NO';
                                        setQuestionerQuestionResults([...questionerQuestionResults]);
                                    }}
                                    required
                                    checked={questionerQuestionResults[currentQuestioneNumber].answer === 'NO'}
                                />
                                <div className="checkbox__success-icon no-border bg-gray"></div>
                                <div className="checkbox__text">{t("No")}</div>
                            </label>
                        </div>
                    )
                }
                {
                    questionerQuestions[currentQuestioneNumber].type === 'TEXT' && (
                        <textarea
                            className="input"
                            value={questionerQuestionResults[currentQuestioneNumber].answer}
                            onChange={(e) => {
                                questionerQuestionResults[currentQuestioneNumber].answer = e.target.value;
                                setQuestionerQuestionResults([...questionerQuestionResults]);
                            }}
                        />
                    )
                }
                {
                    questionerQuestions[currentQuestioneNumber].type === 'MULTIPLE_CHOICE:MULTI' && (
                        <div>
                            {
                                questionerQuestions[currentQuestioneNumber].options && questionerQuestions[currentQuestioneNumber].options.map((option: any, index: number) => (
                                    <label className="checkbox mb-4">
                                        <input
                                            type="checkbox"
                                            onChange={(e) => {
                                                if (questionerQuestionResults[currentQuestioneNumber].answer?.includes(getOptionNameForCurrentLanguage(option))) {
                                                    questionerQuestionResults[currentQuestioneNumber].answer = questionerQuestionResults[currentQuestioneNumber].answer.filter((x: string) => x !== getOptionNameForCurrentLanguage(option));
                                                } else {
                                                    questionerQuestionResults[currentQuestioneNumber].answer.push(getOptionNameForCurrentLanguage(option));
                                                }
                                                setQuestionerQuestionResults([...questionerQuestionResults]);
                                            }}
                                            required
                                            checked={questionerQuestionResults[currentQuestioneNumber].answer?.includes(getOptionNameForCurrentLanguage(option))}
                                        />
                                        <div className="checkbox__success-icon no-border bg-gray"></div>
                                        <div className="checkbox__text">{getOptionNameForCurrentLanguageText(option)}</div>
                                    </label>
                                ))
                            }
                        </div>
                    )

                }
                {
                    questionerQuestions[currentQuestioneNumber].type === 'MULTIPLE_CHOICE:SINGLE' && (
                        <div>
                            {
                                questionerQuestions[currentQuestioneNumber].options && questionerQuestions[currentQuestioneNumber].options.map((option: any, index: number) => (
                                    <label className="checkbox mb-4">
                                        <input
                                            type="checkbox"
                                            onChange={(e) => {
                                                questionerQuestionResults[currentQuestioneNumber].answer = getOptionNameForCurrentLanguage(option);
                                                setQuestionerQuestionResults([...questionerQuestionResults]);
                                            }}
                                            required
                                            checked={questionerQuestionResults[currentQuestioneNumber].answer === getOptionNameForCurrentLanguage(option)}
                                        />
                                        <div className="checkbox__success-icon no-border bg-gray"></div>
                                        <div className="checkbox__text">{getOptionNameForCurrentLanguageText(option)}</div>
                                    </label>
                                ))
                            }
                        </div>
                    )

                }
                {
                    questionerQuestions[currentQuestioneNumber].type === 'SHORT_TEXT' && (
                        <div>
                            <input
                                className="input mb-4"
                                value={questionerQuestionResults[currentQuestioneNumber].answer}
                                onChange={(e) => {
                                    questionerQuestionResults[currentQuestioneNumber].answer = e.target.value;
                                    setQuestionerQuestionResults([...questionerQuestionResults]);
                                }}
                            />
                        </div>
                    )

                }

                {questionerQuestions[currentQuestioneNumber]?.image_mandatory !== -1 && <ImageInput
                    key={currentQuestioneNumber}
                    images={currentImage}
                    setImages={setCurrentImage}
                    title={t("Confirmation Picture")}
                    multiple={false}
                    required={questionerQuestions[currentQuestioneNumber]?.image_mandatory}
                />}
                {questionerQuestions[currentQuestioneNumber]?.image_mandatory === 1 && <p className="text-center">
                    {t("Confirmation picture is mandatory!")}
                </p>}
            </div>

            <div className="flex flex-col items-center mt-6">
                <div>
                    <button
                        type="submit"
                        className="button button--green"
                        onClick={() => handleNext()}
                    >
                        {t("Next")}
                    </button>
                </div>
            </div>
            <div className="flex flex-col items-center mt-4">
                <div>
                    <button type="submit" className="button" onClick={() => {
                        if (currentQuestioneNumber > 0) {
                            let backQuestionNumber = questionerQuestions[currentQuestioneNumber - 1].order;

                            while(skippedQuestions.includes(backQuestionNumber) && backQuestionNumber >= 0){
                                backQuestionNumber--;
                                questionerQuestionResults[backQuestionNumber - 1].answer = questionerQuestions[backQuestionNumber - 1].type === 'MULTIPLE_CHOICE:MULTI' ? [] : null;
                            }

                            if (backQuestionNumber >= 0){
                                let backQuestion = questionerQuestions.findIndex((x: any) => x.order === parseInt(backQuestionNumber));
                                if (backQuestion > -1) {
                                    let newSkippedQuestions = skippedQuestions.filter((x: any) => x < backQuestionNumber);
                                    setSkippedQuestions(newSkippedQuestions);
                                    setCurentQuestionNumber(backQuestion);
                                }
                            }
                        }
                    }}>
                        {t("< Back")}
                    </button>
                </div>
            </div>
        </>
    );
};

export default QuestionerStep;